export default class Item {
  constructor(args = {}) {
    this.store = args.store;
    this.info = args.tagInfo;
  }

  render() {
    let description;
    if (this.info.description) {
      description = `<p class="small line-clamp-2 mb-2">${this.info.description}</p>`;
    } else {
      description = '';
    }
    return `
      <li class="col-lg-4">
        <div class="ratio ratio-4x3 mb-2">
          <img class="object-fit-cover-center" src="${this.info.picPath || this.store.view.noImagePath}" />
        </div>
        <h2 class="fs-5 line-clamp-2">
          <a class="fw-bold text-dark text-decoration-none" href="${this.info.permalink}" target="_blank">
            ${this.info.name}
          </a>
        </h2>
        ${description}
        <div class="small mb-2">
          <span class="text-tertiary">
            <i class="fa-solid fa-map-pin"></i>
          </span>
          ${this.info.address}
        </div>
        <div class="small mb-4">
          評価：${this.info.rating || '---'}
        </div>
        <a class="btn-more" href="${this.info.permalink}" target="_blank">
          もっと見る
        </a>
      </li>
    `;
  }
}
