import ArchiveFilter from './components/ArchiveFilter/ArchiveFilter';

// ArchiveFilter
//
// アーカイブ一覧ページにおける絞り込みフォーム要素パーツ

const archiveFilterElm = document.querySelector('[data-role="archive-filter"]');

if (archiveFilterElm) {
  new ArchiveFilter({ disableAreaMapMode: true });
}
