// eslint-disable-next-line import/no-extraneous-dependencies
import $ from 'jquery';

$(() => {
  // リストに追加するを押下した場合
  if ($('button[name="photo_add_list"]').length) {
    $('button[name="photo_add_list"]').on('click', (e) => {
      const photoId = e.target.id.replace('add_list_', '');
      $(`#add_list_${photoId}`).addClass('d-none');
      $(`#remove_list_${photoId}`).removeClass('d-none');

      // 新規登録;
      $.ajax({
        url: `/photo_dl_selections`,
        beforeSend(xhr) {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
        },
        type: 'POST',
        dataType: 'json',
        data: { photo_id: photoId },
        timeout: 3000,
      }).fail(() => {
        $(`#add_list_${photoId}`).removeClass('d-none');
        $(`#remove_list_${photoId}`).addClass('d-none');
      });
    });

    // リストに追加済を押下した場合
    $('button[name="photo_remove_list"]').on('click', (e) => {
      const photoId = e.target.id.replace('remove_list_', '');
      $(`#add_list_${photoId}`).removeClass('d-none');
      $(`#remove_list_${photoId}`).addClass('d-none');

      $.ajax({
        url: `/photo_dl_selections/${photoId}`,
        beforeSend(xhr) {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
        },
        type: 'DELETE',
        dataType: 'json',
        timeout: 3000,
      }).fail(() => {
        $(`#add_list_${photoId}`).removeClass('d-none');
        $(`#remove_list_${photoId}`).addClass('d-none');
      });
    });
  }

  // 入力チェック
  // 戻り値についてtrue: 未入力なし/email妥当性OK false: 未入力あり/emailが不正
  function isActivationBulkDownload() {
    // 利用規約チェックボックス
    // On:  一括ダウンロードボタンを活性化
    // Off: 一括ダウンロードボタンを非活性
    return $('#photo_terms_of_use').prop('checked');
  }

  function setBulkDownloadDisabled() {
    $('#bulk_download').prop('disabled', !isActivationBulkDownload());
  }

  // 一括ダウンロードする
  if ($('#bulk_download').length) {
    // 氏名が変更された場合
    $('#person_id').change(() => {
      setBulkDownloadDisabled();
    });
    // emailが変更された場合
    $('#email_id').change(() => {
      setBulkDownloadDisabled();
    });
    // 利用目的が変更された場合
    $('#purpose_id').change(() => {
      setBulkDownloadDisabled();
    });
    // 利用規約のチェックボックスを変更された場合
    $('#photo_terms_of_use').change(() => {
      setBulkDownloadDisabled();
    });
    // 初期表示設定
    setBulkDownloadDisabled();
  }

  if ($('#photo-library-download').length) {
    window.location.href = '/photo-library/download';
  }
});
