export default class PrefPresetCard {
  constructor({ elm, app }) {
    this.elm = elm;
    this.app = app;
    this.params = {
      pref1: this.elm.dataset.pref1,
      pref2: this.elm.dataset.pref2,
      pref3: this.elm.dataset.pref3,
      pref4: this.elm.dataset.pref4,
      taste4: this.elm.dataset.taste4,
    };

    this.elm.addEventListener('click', () => {
      this.app.prefPresetCards.forEach((card) => {
        card.elm.classList.remove(this.app.activeClassName);
      });
      this.active();

      this.app.updatePrefSliders(this.params);
      return false;
    });
  }

  active() {
    this.elm.classList.add(this.app.activeClassName);
  }

  inactive() {
    this.elm.classList.remove(this.app.activeClassName);
  }
}
