import { SelectBundler } from './SelectBundler';
import { Submit } from './Submit';

// [note 23.01.30]
// - izuで使ったスクリプトをhakodate用に「出発地点に現在地を割り当てることができる機能」を追加して実装
// - izuで使ったスクリプトをhakodate用に「ロード時に選択されている要素を考慮する機能」を追加して実装
// - 必要な機能自体がしれてるので、イベントドリブンな感じで手続き型に書き直しても良い

// [インスタンスの入れ子イメージ]
// - App
//    - SelectBundler x 2
//      - SelectBtn x n
//      - SelectInput
//    - Submit

export default class Gmap2PointLinkCreateUI {
  constructor({ appSelector, locale = 'ja' }) {
    this.store = {
      config: {
        locale,
        submitBaseUrl: 'https://www.google.co.jp/maps/dir/',
        selectorName: {
          bundler: '[data-mp-role="gmap-2point-link-create-ui-bundler"]',
          btn: '[data-mp-role="gmap-2point-link-create-ui-btn"]',
          input: '[data-mp-role="gmap-2point-link-create-ui-input"]',
          submit: '[data-mp-role="gmap-2point-link-create-ui-submit"]',
        },
        className: {
          activeSelectBtn: 'is-active',
          activeSelectInput: 'is-active',
        },
        msg: {
          validError: '出発地点と選択地点の両方を選択してください',
          startIsCrLocationMsg:
            'スタート地点に「現在地」が選択されました。GoogleMap上で現在地検索が有効である場合、正しく検索が行われます。',
        },
      },
      state: {
        start: '',
        end: '',
        isCrLocationSearchMode: false, // 出発地点に「現在地」が選択されているか?
      },
    };

    this.node = document.querySelector(appSelector);

    // DOMに紐ついたインスタンスを作成
    // SelectBundlerに関しては、さらにそのインスタンス内で子DOMインスタンスを管理

    new Submit({
      node: this.node.querySelector(this.store.config.selectorName.submit),
      store: this.store,
    });

    this.node.querySelectorAll(this.store.config.selectorName.bundler).forEach((node) => {
      new SelectBundler({
        node,
        store: this.store,
        type: node.dataset.mpBundleType, // start or endを暗黙で想定
      });
    });
  }
}
