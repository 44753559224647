import Swiper from 'swiper/bundle';

export default class ThreeslideCarouselApp {
  constructor({ node }) {
    // swiper自体をラップする要素(node)を起点に、
    // その子要素として展開されるswiper関連のDOM群をメンバとして定義しておく
    //
    this.node = node;
    this.swiperNode = this.node.querySelector('.swiper-container');
    this.swiperNextNode = this.node.querySelector('.swiper-cmn__btn--type_next');
    this.swiperPrevNode = this.node.querySelector('.swiper-cmn__btn--type_prev');

    // 条件分岐に必要な要素をメンバ化
    //
    this.slideLength = this.node.querySelectorAll('.swiper-slide').length;
    this.spSlidesPerView = 1;
    this.pcSlidesPerView = 3;
    this.isSlideNumIrregularCase = this.slideLength === 2;
    this.bp = 768;

    // 一部のswiperオプションをオブジェクト化
    //
    this.defaultSwiperOpts = {
      loop: this.isFullSliders(),
      centeredSlides: this.slideLength === 1,
      nextEl: this.isFullSliders() ? this.swiperNextNode : null,
      prevEl: this.isFullSliders() ? this.swiperPrevNode : null,
    };
    // デフォルト値に戻すことがあるので別変数にバインド
    this.swiperOpts = this.defaultSwiperOpts;

    // swiperインスタンス用
    //
    this.swiper = null;

    // swiperおよびviewの初期化
    //
    this.initSwiper();

    // イレギュラーケースのみを監視
    //
    window.addEventListener('resize', () => {
      this.handleResize();
    });
  }

  initSwiper() {
    this.createSwiper();
    if (!this.isFullSliders()) this.hideNavigationView();
  }

  isFullSliders() {
    return this.slideLength > this.pcSlidesPerView;
  }

  isSp() {
    return window.innerWidth < this.bp;
  }

  createSwiper() {
    this.swiper = new Swiper(this.swiperNode, {
      slidesPerView: this.spSlidesPerView,
      loop: this.swiperOpts.loop,
      centeredSlides: this.swiperOpts.centeredSlides,
      speed: 400,
      breakpoints: {
        [this.bp]: {
          slidesPerView: this.pcSlidesPerView,
          spaceBetween: 32,
        },
      },
      navigation: {
        nextEl: this.swiperOpts.nextEl,
        prevEl: this.swiperOpts.prevEl,
      },
    });
  }

  hideNavigationView() {
    this.swiperNextNode.classList.add('d-none');
    this.swiperPrevNode.classList.add('d-none');
  }

  showNavigationView() {
    this.swiperNextNode.classList.remove('d-none');
    this.swiperPrevNode.classList.remove('d-none');
  }

  handleResize() {
    // イレギュラーケースに限定
    if (this.isSp() && this.isSlideNumIrregularCase) {
      this.showNavigationView();
      this.swiper.destroy(false, true);
      this.swiperOpts = {
        loop: true,
        centeredSlides: false,
        nextEl: this.swiperNextNode,
        prevEl: this.swiperPrevNode,
      };
      this.createSwiper();
    }

    if (!this.isSp() && this.isSlideNumIrregularCase) {
      this.hideNavigationView();
      this.swiper.destroy(false, true);
      this.swiperOpts = this.defaultSwiperOpts;
      this.createSwiper();
    }
  }
}
