/* eslint-disable import/prefer-default-export */
export class Submit {
  constructor({ node, store }) {
    this.node = node;
    this.store = store;

    this.node.addEventListener('click', (e) => {
      e.preventDefault();
      const {start} = this.store.state;
      const {end} = this.store.state;
      const {locale} = this.store.config;

      if (start && end) {
        // スタート地点に現在地が選択されていた場合はメッセージを出す
        if (store.state.isCrLocationSearchMode) {
          // eslint-disable-next-line no-alert
          alert(this.store.config.msg.startIsCrLocationMsg);
        }
        window.open(`${this.store.config.submitBaseUrl}${start}/${end}?hl=${locale}`, '_blank');
      } else {
        // eslint-disable-next-line no-alert
        alert(this.store.config.msg.validError);
      }

      return false;
    });
  }
}
