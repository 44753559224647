import Aic from './Aic/App';

if (document.querySelector('[data-mp-role="aic-form"]')) {
  // eslint-disable-next-line no-unused-vars
  const aic = new Aic({
    aicAPIBaseURI: `/concierge.js`,
    apiKey: 'd0wI2Hldi2itkfCPzR4j', // dummy
    place: 'hakodate_city', // dummy
  });
}
