// header関連
if (document.querySelector('.global-header') !== null) {
  // menu開閉ボタン
  const globalHeader = document.querySelector('.global-header');
  const overlay = document.createElement('div');
  overlay.className = 'overlay';
  globalHeader.appendChild(overlay);
  document.querySelector('.js-menu-toggle').addEventListener('click', function f() {
    this.classList.toggle('is-menu-open');
    overlay.classList.toggle('is-active');
  });
  overlay.addEventListener('click', function f () {
    document.querySelector('.js-menu-toggle').classList.remove('is-menu-open');
    this.classList.remove('is-active');
  });

  // グロナビ開閉
  const globalMenuOpeners = document.querySelectorAll('.js-global-main-menu-toggle');
  for (let i = 0; i < globalMenuOpeners.length; i += 1) {
    globalMenuOpeners[i].addEventListener('click', function f() {
      this.classList.toggle('is-main-menu-open');
    });
  }

  // 言語選択ボタン
  const languageMenuOpener = document.querySelectorAll('.js-language-list-toggle');
  for (let i = 0; i < languageMenuOpener.length; i += 1) {
    languageMenuOpener[i].addEventListener('click', function f() {
      this.classList.toggle('is-language-list-open');
    });
  }
}
