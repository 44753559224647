/* eslint-disable import/prefer-default-export */
export class SelectBtn {
  constructor({ node, store, type, inactiveSelectNodes, resetInputNodes }) {
    this.node = node;
    this.store = store;
    this.type = type;
    this.name = node.dataset.searchName;
    this.activeClassName = store.config.className.activeSelectBtn;
    this.isCrLocationSelectNode = this.node.dataset.searchOption === 'cr-location';

    this.init();

    this.node.addEventListener('click', () => {
      // reset related nodes state
      inactiveSelectNodes();
      resetInputNodes();

      // change the node state
      this.node.classList.add(this.activeClassName);

      // change store state
      this.updateLocationStore();
      this.updateIsCrLocationSearchModeStore();
    });
  }

  // start もしくは end のstoreを更新
  updateLocationStore() {
    if (this.node.classList.contains(this.activeClassName)) this.store.state[this.type] = this.name;
  }

  // 「現在地」が選択されていた場合は現在地モードをonに  (スタート候補固有)
  updateIsCrLocationSearchModeStore() {
    this.store.state.isCrLocationSearchMode = this.type === 'start' && this.isCrLocationSelectNode;
  }

  init() {
    this.updateLocationStore();
    this.updateIsCrLocationSearchModeStore();
  }
}
