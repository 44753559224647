// eslint-disable-next-line import/no-extraneous-dependencies
import $ from 'jquery';

function toggleAccordion() {
  if ($(this).hasClass('active')) {
    $(this).toggleClass('active');
    $(this).next('.accordion-cmn-answer').fadeOut();
  } else {
    $(this).toggleClass('active');
    $(this).next('.accordion-cmn-answer').fadeIn();
  }
}

if (document.querySelector('.accordion-cmn') !== null) {
  $(() => {
    $('.accordion-cmn-question').click(toggleAccordion);
  });
}

function toggleFooterMenu() {
  if ($(this).hasClass('active')) {
    $(this).toggleClass('active');
    $(this).next('.global-footer-menu__subitem').fadeOut();
  } else {
    $(this).toggleClass('active');
    $(this).next('.global-footer-menu__subitem').fadeIn();
  }
}

if (document.querySelector('.global-footer-menu') !== null) {
  $(() => {
    $('.global-footer-menu__item').click(toggleFooterMenu);
  });
}
