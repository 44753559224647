// eslint-disable-next-line import/no-extraneous-dependencies
import $ from 'jquery';
import Gmap2PointLinkCreateUI from './components/Gmap2PointLinkCreateUI/App';

// [note] by smmt
// Gmap2PointLinkCreateUI
// 2点間のGoogle Mapのリンクを作り出すUIを定義する小規模なアプリ
//
// [利用方法]
// 1. アプリとして利用したい領域の最も外側のhtmlタグに特定のクラスを付加
//  => 例: <div class=".js-access-gmap-2point-link-create-ui">
// 2. Gmap2PointLinkCreateUIクラスのインスタンス生成時に引数としてそのセレクタ名を渡す
//  => 以下コード参照
// 3. アプリ内で利用されるhtmlタグに対し、アプリで利用するためのdata属性をマッピングする
//  => 例: <div data-mp-role="gmap-2point-link-create-ui-bundler">
//  => 詳しくはGmap2PointLinkCreateUI/App.js内のconfig情報参照

$(() => {
  if (document.querySelector('.js-access-gmap-2point-link-create-ui')) {
    new Gmap2PointLinkCreateUI({
      appSelector: '.js-access-gmap-2point-link-create-ui',
      locale: 'ja',
    });
  }
});
