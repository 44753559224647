// eslint-disable-next-line import/no-extraneous-dependencies
import $ from 'jquery';

if (document.querySelector('#tab-cmn') !== null) {
  $(() => {
    $('.tab-cmn-menu__item').on('click',  (event) => {
      const tabWrap = $(event.currentTarget).parents('#tab-cmn');
      const tabBtn = tabWrap.find('.tab-cmn-menu__item');
      const tabContents = tabWrap.find('.tab-cmn-contents');
      tabBtn.removeClass('is-active');
      $(event.currentTarget).addClass('is-active');
      const elmIndex = tabBtn.index($(event.currentTarget));
      tabContents.removeClass('is-active');
      tabContents.eq(elmIndex).addClass('is-active');
    });
  });
}

if (document.querySelector('.top-tab-cmn') !== null) {
  $(() => {
    $('.top-tab-cmn-menu__item').on('click',  (event) => {
      const tabWrap = $(event.currentTarget).parents('.top-tab-cmn');
      const tabBtn = tabWrap.find('.top-tab-cmn-menu__item');
      const tabContents = tabWrap.find('.top-tab-cmn-contents');
      tabBtn.removeClass('active');
      $(event.currentTarget).addClass('active');
      const elmIndex = tabBtn.index($(event.currentTarget));
      tabContents.removeClass('active');
      tabContents.eq(elmIndex).addClass('active');
    });
  });
}
