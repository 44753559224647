/* eslint-disable import/prefer-default-export */
export class SelectInput {
  constructor({ node, store, type, inactiveSelectNodes }) {
    this.node = node;
    this.store = store;
    this.type = type;
    this.activeClassName = store.config.className.activeSelectBtn;

    this.init();

    this.node.addEventListener('input', () => {
      // reset related nodes state
      inactiveSelectNodes();

      // change the node state
      this.node.classList.add(this.activeClassName);

      // change store state
      this.updateLocationStore();
      this.inactiveIsCrLocationSearchModeStore();
    });
  }

  // start もしくは end のstoreを更新
  updateLocationStore() {
    if (this.node.classList.contains(this.activeClassName)) {
      this.store.state[this.type] = this.node.value;
    }
  }

  // 基本、このnodeの選択は、現在地検索のボタンの選択が外れることになる (スタート候補固有)
  inactiveIsCrLocationSearchModeStore() {
    if (this.type === 'start') this.store.state.isCrLocationSearchMode = false;
  }

  init() {
    this.updateLocationStore();
    this.inactiveIsCrLocationSearchModeStore();
  }
}
