class AreaCheckboxGroup {
  constructor({ parentElm, app }) {
    this.parentElm = parentElm;
    this.app = app;
    this.groupId = this.parentElm.dataset.groupId;
    this.childElms = document.querySelectorAll(
      `[data-group-id="${this.groupId}"]:not(${this.app.config.areaParentCkSelectorName})`
    );

    this.parentElm.addEventListener('click', () => {
      // 自身が引き起こしたイベントに対応したviewのみの更新
      this.updateChildrenView();

      // viewの状態を見てstate更新 (todo : 本来は逆が好ましい)
      this.updateAreaIdsState();
      this.app.render();
    });

    this.childElms.forEach((elm) => {
      elm.addEventListener('click', () => {
        // 自身が引き起こしたイベントに対応したviewのみの更新
        this.updateParentView();

        // viewの状態を見てstate更新 (todo : 本来は逆が好ましい)
        this.updateAreaIdsState();
        this.app.render();
      });
    });
  }

  activeParent() {
    this.parentElm.checked = true;
  }

  inactiveParent() {
    this.parentElm.checked = false;
  }

  activeChildren() {
    this.childElms.forEach((elm) => {
      /* eslint-disable no-param-reassign */
      elm.checked = true;
      /* eslint-enable no-param-reassign */
    });
  }

  inactiveChildren() {
    this.childElms.forEach((elm) => {
      /* eslint-disable no-param-reassign */
      elm.checked = false;
      /* eslint-enable no-param-reassign */
    });
  }

  updateChildrenView() {
    if (this.parentElm.checked) this.activeChildren();
    if (!this.parentElm.checked) this.inactiveChildren();
  }

  updateParentView() {
    const hasUncheckedChildMember = () => Array.from(this.childElms).find((elm) => elm.checked === false);
    if (hasUncheckedChildMember()) this.inactiveParent();
    if (!hasUncheckedChildMember()) this.activeParent();
  }

  updateAreaIdsState() {
    if (this.parentElm.checked) {
      this.app.state.activeAreaIds.push(this.groupId);
    }

    if (!this.parentElm.checked) {
      this.app.state.activeAreaIds = this.app.state.activeAreaIds.filter((id) => id !== this.groupId);
    }
  }

  // 外部の関連area要素側でstateが更新された場合などに対応
  render() {
    const isActiveArea = () => this.app.state.activeAreaIds.find((id) => this.groupId === id);
    const hasUncheckedChildMember = () => Array.from(this.childElms).find((elm) => elm.checked === false);
    if (isActiveArea()) {
      this.activeParent();
      this.activeChildren();
    }
    if (!isActiveArea()) {
      this.inactiveParent();
    }
    // [note]
    // エリアが「非アクティブ」である時、
    // 同一のエリアに属する子checkboxは、もれなくチェックが入っている場合のみ
    // 連動して非アクティブとなる。
    if (!isActiveArea() && !hasUncheckedChildMember()) {
      this.inactiveChildren();
    }
  }
}

export default AreaCheckboxGroup;
