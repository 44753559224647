// /aboutページにおけるメールアドレスの暗号化を行う
// eslint-disable-next-line import/no-extraneous-dependencies
import $ from 'jquery';

$(() => {
  function converter(M) {
    let str = '';
      let strAs = '';
    for (let i = 0; i < M.length; i += 1) {
      strAs = M.charCodeAt(i);
      str += String.fromCharCode(strAs + 1);
    }
    return str;
  }

  window.mailToManagementByAbout = () => {
    // eslint-disable-next-line no-eval
    eval(
      `${String.fromCharCode(
        108,
        111,
        99,
        97,
        116,
        105,
        111,
        110,
        46,
        104,
        114,
        101,
        102,
        32,
        61,
        32,
        39,
        109,
        97,
        105,
        108,
        116,
        111,
        58
      ) +
        converter(
          String.fromCharCode(
            103,
            96,
            106,
            110,
            44,
            106,
            96,
            109,
            49,
            63,
            98,
            104,
            115,
            120,
            45,
            103,
            96,
            106,
            110,
            99,
            96,
            115,
            100,
            45,
            103,
            110,
            106,
            106,
            96,
            104,
            99,
            110,
            45,
            105,
            111,
            62,
            114,
            116,
            97,
            105,
            100,
            98,
            115,
            60
          )
        ) 
        }'`
    );
  };

  window.mailToManagementTrusteeByAbout = () => {
    // eslint-disable-next-line no-eval
    eval(
      `${String.fromCharCode(
        108,
        111,
        99,
        97,
        116,
        105,
        111,
        110,
        46,
        104,
        114,
        101,
        102,
        32,
        61,
        32,
        39,
        109,
        97,
        105,
        108,
        116,
        111,
        58
      ) +
        converter(
          String.fromCharCode(
            103,
            96,
            106,
            110,
            97,
            116,
            113,
            96,
            63,
            103,
            96,
            106,
            110,
            114,
            103,
            104,
            109,
            45,
            105,
            111,
            62,
            114,
            116,
            97,
            105,
            100,
            98,
            115,
            60
          )
        ) 
        }'`
    );
  };

  if ($('#management_trustee').length) {
    $('#management_trustee').html((_, element) => `${element  }<a href="javascript:mailToManagementTrusteeByAbout()">お問い合わせはこちら</a>`);
  }

  if ($('#management').length) {
    $('#management').html((_, element) => `${element  }<a href="javascript:mailToManagementByAbout()">お問い合わせはこちら</a>`);
  }
});
