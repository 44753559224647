// eslint-disable-next-line import/no-extraneous-dependencies
import $ from 'jquery';

$(() => {
  // リストに追加するを押下した場合
  if ($('.Sec-articles-tab').length) {
    $('li.col-auto').on('click', (e) => {
      if (document.querySelectorAll('li.col-auto')) {
        document.querySelectorAll('li.col-auto :first-child').forEach((monthLiElement) => {
          if (
            monthLiElement.dataset.year === e.target.dataset.year &&
            monthLiElement.dataset.month === e.target.dataset.month
          ) {
            $(monthLiElement).removeClass('border-dark bg-white text-dark');
            $(monthLiElement).addClass('border-primary bg-primary text-white');
          } else {
            $(monthLiElement).removeClass('border-primary bg-primary text-white');
            $(monthLiElement).addClass('border-dark bg-white text-dark');
          }
        });
      }
    });

    // 年が選択された場合、登録の最終月を選択状態にする
    $('li.tab-cmn-menu__item').on('click', (e) => {
      // キーワードを初期化する
      $('#q_translations_title_cont').val('');
      if (document.querySelectorAll('li.col-auto')) {
        document.querySelectorAll('li.col-auto :first-child').forEach((monthLiElement) => {
          if (
            monthLiElement.dataset.year === e.target.dataset.year &&
            monthLiElement.dataset.month === e.target.dataset.month
          ) {
            $(monthLiElement).removeClass('border-dark bg-white text-dark');
            $(monthLiElement).addClass('border-primary bg-primary text-white');
          } else {
            $(monthLiElement).removeClass('border-primary bg-primary text-white');
            $(monthLiElement).addClass('border-dark bg-white text-dark');
          }
        });
      }
    });

    // 月が選択された場合
    $('li.article-month').on('click', () => {
      if ($('#q_translations_title_cont').val().length > 0) {
        // キーワードを初期化する
        $('#q_translations_title_cont').val('');
        // キーワード検索後は年・月が未選択状態であり、月が選択された場合は最新の年を選択状態にする必要がある
        // querySelectorにしているのは先頭の要素を取得する為。
        const yearEelemtn = document.querySelector('li.tab-cmn-menu__item');
        $(yearEelemtn).removeClass('is-active');
        $(yearEelemtn).addClass('is-active');
      }
    });
  }
});
