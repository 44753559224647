import Swiper from 'swiper/bundle';
import ThreeslideCarouselApp from './components/ThreeslideCarouselApp';

// Shared
//

if (document.querySelector('#single-mv') !== null) {
  const slideLength = document.querySelectorAll('#single-mv .swiper-slide').length;
  if (slideLength > 1) {
    // eslint-disable-next-line no-new
    new Swiper('#single-mv', {
      slidesPerView: 1,
      loop: true,
      autoplay: true,
      speed: 1000,
      pagination: {
        el: '#single-mv .swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
    });
  }
}

const swiperThreeslideCmn = document.querySelectorAll('.swiper-threeslide-cmn');
if (swiperThreeslideCmn) {
  swiperThreeslideCmn.forEach((node) => {
    // eslint-disable-next-line no-new
    new ThreeslideCarouselApp({ node });
  });
}

const nearbyCarousels = document.querySelectorAll('.nearby-carousel');
if (nearbyCarousels) {
  nearbyCarousels.forEach((elm) => {
    const swiper = elm.querySelector('.swiper-container');
    // eslint-disable-next-line no-new
    new Swiper(swiper, {
      slidesPerView: 1,
      spaceBetween: 32,
      loop: true,
      speed: 1000,
      navigation: {
        nextEl: elm.querySelector('.swiper-cmn__btn--type_next'),
        prevEl: elm.querySelector('.swiper-cmn__btn--type_prev'),
      },
      breakpoints: {
        768: {
          slidesPerView: 2,
        },
      },
    });
  });
}

// For Top Page
//

const topSwiperOneslides = document.querySelectorAll('.top-swiper-oneslide');
if (topSwiperOneslides) {
  topSwiperOneslides.forEach((elm) => {
    const swiper = elm.querySelector('.swiper-container');
    // eslint-disable-next-line no-new
    new Swiper(swiper, {
      slidesPerView: 1,
      loop: true,
      speed: 1000,
      spaceBetween: 32,
      navigation: {
        nextEl: elm.querySelector('.swiper-cmn__btn--type_next'),
        prevEl: elm.querySelector('.swiper-cmn__btn--type_prev'),
      },
      pagination: {
        el: elm.querySelector('.swiper-pagination'),
        type: 'bullets',
        clickable: true,
      },
      breakpoints: {
        1012: {
          spaceBetween: 32,
        },
      },
    });
  });
}

const topSwiperThreeslides = document.querySelectorAll('.top-swiper-threeslide');
if (topSwiperThreeslides) {
  topSwiperThreeslides.forEach((elm) => {
    const swiper = elm.querySelector('.swiper-container');
    // eslint-disable-next-line no-new
    new Swiper(swiper, {
      slidesPerView: 1,
      loop: true,
      speed: 1000,
      breakpoints: {
        768: {
          slidesPerView: 3,
          spaceBetween: 32,
        },
      },
      navigation: {
        nextEl: elm.querySelector('.swiper-cmn__btn--type_next'),
        prevEl: elm.querySelector('.swiper-cmn__btn--type_prev'),
      },
      pagination: {
        el: elm.querySelector('.swiper-pagination'),
        type: 'bullets',
        clickable: true,
      },
    });
  });
}

const topSwiperFourslide = document.querySelectorAll('.top-swiper-fourslide');
if (topSwiperFourslide) {
  topSwiperFourslide.forEach((elm) => {
    const swiper = elm.querySelector('.swiper-container');
    // eslint-disable-next-line no-new
    new Swiper(swiper, {
      slidesPerView: 1,
      spaceBetween: 32,
      loop: true,
      speed: 1000,
      breakpoints: {
        1012: {
          slidesPerView: 4,
        },
        768: {
          slidesPerView: 2,
        },
      },
      navigation: {
        nextEl: elm.querySelector('.swiper-cmn__btn--type_next'),
        prevEl: elm.querySelector('.swiper-cmn__btn--type_prev'),
      },
      pagination: {
        el: elm.querySelector('.swiper-pagination'),
        type: 'bullets',
        clickable: true,
      },
    });
  });
}

// For charm page
//

const charmMvSwiperElms = document.querySelectorAll("[data-role='charm-mv-swiper']");
if (charmMvSwiperElms.length) {
  charmMvSwiperElms.forEach((elm) => {
    // eslint-disable-next-line no-new
    new Swiper(elm, {
      loop: true,
      slidesPerView: 2.5,
      speed: 4000,
      allowTouchMove: false,
      autoplay: {
        delay: 0,
      },
      breakpoints: {
        768: {
          slidesPerView: 4,
        },
      },
    });
  });
}

const customSwipers = document.querySelectorAll('.custom-swiper-container');
customSwipers.forEach((elm) => {
  const slides = elm.querySelectorAll('.custom-swiper-slide');
  const slideCount = slides.length;
  const navigation = elm.parentNode.querySelector('.custom-swiper-navigation');

  // スマホでの最小スライド数
  const minSlideCountMobile = 2;
  // タブレット以上での最小スライド数
  const minSlideCountTablet = 4;

  if (
    (window.innerWidth < 768 && slideCount >= minSlideCountMobile) ||
    (window.innerWidth >= 768 && slideCount >= minSlideCountTablet)
  ) {
    // eslint-disable-next-line no-new
    new Swiper(elm, {
      slidesPerView: 1,
      loop: true,
      speed: 1000,
      breakpoints: {
        768: {
          slidesPerView: 3,
          spaceBetween: 16,
        },
      },
      navigation: {
        nextEl: navigation.querySelector('.swiper-cmn__btn--type_next'),
        prevEl: navigation.querySelector('.swiper-cmn__btn--type_prev'),
      },
    });
  } else {
    // Swiperが発動されていない場合、ナビゲーションを非表示にする
    navigation.style.display = 'none';
    // Swiperが発動していない場合、custom-swiper-containerにnon-activeクラスを追加
    elm.classList.add('non-active');
  }
});

if (document.querySelector('#charm-slider') !== null) {
  // eslint-disable-next-line no-new
  new Swiper('#charm-slider', {
    slidesPerView: 1,
    centeredSlides: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    loop: true,
    speed: 1000,
    breakpoints: {
      768: {
        slidesPerView: 1.4,
        spaceBetween: 11,
      },
    },
    navigation: {
      prevEl: '#charm-slider .swiper-button-prev',
      nextEl: '#charm-slider .swiper-button-next',
    },
  });
}
