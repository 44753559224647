import ApprovalRequiredFixBalloon from './components/ApprovalRequiredFixBalloon';

// GDPRのバルーン
if (document.querySelector('[data-role="approval-required-fix-balloon"]') !== null) {
  const approvalRequiredFixBalloon = new ApprovalRequiredFixBalloon();
  approvalRequiredFixBalloon.init();
}

// 画像の保存禁止簡易処理
function imageGuard(selector) {
  const guardSelector = document.querySelectorAll(selector);

  /* eslint-disable no-plusplus */
  for (let n = 0; n < guardSelector.length; n++) {
    guardSelector[n].addEventListener(
      'contextmenu',
      (e) => {
        e.preventDefault();
      },
      false
    );
  }

  const guardStyle = {
    'pointer-events': 'none',
    '-webkit-touch-callout': 'none',
    '-moz-touch-callout': 'none',
    'touch-callout': 'none',
    '-webkit-user-select': 'none',
    '-moz-user-select': 'none',
    'user-select': 'none',
  };

  Object.keys(guardStyle).forEach((v) => {
    for (let n = 0; n < guardSelector.length; n++) {
      guardSelector[n].style[v] = guardStyle[v];
    }
  });
  /* eslint-enable no-plusplus */
}

document.addEventListener('DOMContentLoaded', () => {
  imageGuard('img');
});

const backToTopButton = document.querySelector('.totop');
if(backToTopButton !== null) {
  const scrollHeight = 200;
  
  let scrollPosition = window.scrollY;
  let ticking = false;
  
  const doSomething = (scrollPos) => {
    if (scrollPos >= scrollHeight) {
      backToTopButton.classList.add('totop--active');
    } else {
      backToTopButton.classList.remove('totop--active');
    }
  }

  doSomething(scrollPosition)
  
  window.addEventListener('scroll', () => {
    scrollPosition = window.scrollY;
  
    if (!ticking) {
      window.requestAnimationFrame(() => {
        doSomething(scrollPosition);
        ticking = false;
      });

      ticking = true;
    }
  },{ passive: true });
}
